<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <MovingToAddressFormPart @result="onResult" @change="onChange" />

      <h2 class="form-modal__part-title">Tipo de vivienda</h2>

      <MovingToPropertyTypeFormPart @result="onResult" @change="onChange" />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import MovingToPropertyTypeFormPart from 'chimera/moving/components/form/parts/movingToPropertyType/MovingToPropertyTypeFormPart'
import MovingToAddressFormPart from 'chimera/moving/components/form/parts/movingToAddress/MovingToAddressFormPart'
import NextStep from '~/pages/solicitudes-de-cotizacion/condiciones-de-la-mudanza'

export default {
  name: 'MovingToFormStep',

  components: {
    MovingToAddressFormPart,
    MovingToPropertyTypeFormPart,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  data: () => ({
    willTransitionOnValid: true,
  }),

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>
